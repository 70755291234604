.job {
    margin-bottom: 50px;
    margin-top: 150px;

    .text p {
        margin: 20px auto;
        font-size: 14.5px;
    }

    div{
        color: #000;
    }

    span{
        font-weight: 600;
    }

    p{
        margin: 0;
    }

    h1{
        font-size: 28px;
    }

    h2{
        font-size: 26px;
    }
    .card:hover{
        transform:none;
    }
    .card {
        padding: 30px 20px 30px 30px;

        .btn {
            background-color: #2db7ff;
            color: #ffffff;
            font-size: 18px;
            padding: 0px 30px;
            border-radius: 50px;
            font-weight: 600;
            width: 100%;
            max-width: 260px;
            height: 50px;
            transform: translate3d(0px, 0%, 0px);
            text-decoration: none;
            letter-spacing: .05em;
            transition-delay: .1s;
            overflow: hidden;
        }
    
        .btn:hover {
            background: #ffffff;
            border: 2px solid #2db7ff;
            color: #2db7ff;
            transition: background .2s linear;
            transition-delay: .1s;
        }

    }

}