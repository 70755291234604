.store-locator-container {
  display: flex;
}

.sidebar {
  width: 20%;
  background-color: #f1f1f1;
  height: 615px; /* Set the desired height for the div */
overflow-y: auto; /* Add a vertical scroll bar when content overflows */
}
.address-card{
margin-right: -14px;
}

.address-item {
  padding: 10px;
  cursor: pointer;
}

.address-item.active {
  background-color: #ccc;
}

.map {
  flex-grow: 1;
}

.contact-page{
background-color: #ededed;
}

.map-marker{
width: 200px;
}
.main-address{
font-size: 12px;
}
.map {
width: 100%;
height: 100%;
}

.map > div {
width: 100%;
height: 100%;
}
select {
text-align: center;
text-align-last: center;
-moz-text-align-last: center;
}
#brand{
margin-left: 78px !important;
}
@media only screen and (max-width: 767px) {
#brand{
  margin-left: 0 !important;
}



}


.store-locator-container {
display: flex;
flex-wrap: wrap;
}

.sidebar {
flex: 0 0 300px; /* Adjust the sidebar width as needed */
padding: 20px;
background-color: #f7f7f7; /* Adjust the background color as needed */
}

.sidebar-header {
margin-bottom: 20px;
}

.address-item {
cursor: pointer;
padding: 10px;
border-bottom: 1px solid #ddd; /* Adjust the border style as needed */
}

.address-item:last-child {
border-bottom: none;
}

.address-item.active {
background-color: #e6e6e6; /* Adjust the active item background color as needed */
}

.map {
flex: 1;
height: 400px; /* Adjust the initial map height as needed */
}

/* Media queries for responsive layout */
@media (max-width: 767.98px) {
.sidebar {
  flex: 0 0 100%;
}

.map {
  flex: 0 0 100%;
  height: 300px; /* Adjust the map height on smaller screens */
}
}

.google-map-section{
margin-bottom: 50px !important;
}


@media only screen and (min-width: 767px) {

.map, .map>div{
  height: 78% !important;
}
}