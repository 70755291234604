/* Media Image-gallery starting */

.image-gallery img {
  width: 100%;          /* Ensure images fill their container (Col) */
  height: 200px;        /* Desired height for each image */
  object-fit: cover;    /* Crop image to cover container while maintaining aspect ratio */
  object-position: center; /* Center the cropped portion of the image */
}

.Feaild{
  
    margin-top: 3% !important;
    margin-bottom: 1%;
   display: flex;
   justify-content: center;
   margin-bottom: 50px;
}
.Feaild .col-lg-2{
   padding: 0px;
   width: 150px;
   display: flex;
   justify-content: center;
}
.images-row{
   display: flex;
   justify-content: center;

}
.images-row .col-lg-3{
   padding: 0px;
   width: 270px;
   display: flex;
   justify-content: center;
   padding-top: 25px;
}

.hover-img {
   color: #fff;
   display: inline-block;
   
   overflow: hidden;
   position: relative;
 }
 
 .hover-img * {
   box-sizing: border-box;
   transition: all 0.45s ease;
 }
 
//  .hover-img:before,
//  .hover-img:after {
//    background-color: rgba(0, 0, 0, 0.6);
//    width: 80%;
//    height: 80%;
//    position: absolute;
//    top: 10%;
//    bottom: 0;
//    left: 10%;
//    right: 0;
//    content: '';
//    transition: all 0.3s ease;
//    z-index: 1;
//    opacity: 0;
//    transform: scaleY(2);
//  }
 
 .hover-img img {
   vertical-align: top;
   backface-visibility: hidden;
 }
 
 .hover-img figcaption {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   align-items: center;
   z-index: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
   line-height: 1.1em;
   opacity: 0;
   z-index: 2;
   transition-delay: 0.1s;
   font-size: 24px;
   font-family: sans-serif;
   font-weight: 400;
   letter-spacing: 1px;
   text-transform: uppercase;
 }
 
 .hover-img:hover:before,
 .hover-img:hover:after {
   transform: scale(1);
   opacity: 1;
 }
 
 .hover-img:hover > img {
   opacity: 0.7;
 }
 
 .hover-img:hover figcaption {
   opacity: 1;
  
  
 }

.titles{
    padding: 6px;
}

.Active{
    background-color: #008CD0;
    border-radius: 5px;
    color:white;
    cursor: pointer;
    padding: 4px;
}

.showall:hover{
    background-color: #008CD0;
    border-radius: 5px;
    color:white;
    cursor: pointer;
    padding: 4px;
}
 .titles:hover{
    background-color: #008CD0;
    border-radius: 5px;
    color:white;
    cursor: pointer;
 }


 .visually-hidden{
    display: none;
 }
/* Media Image-gallery Ending */
.hover-img{
    width: 100%;
}
.hover-img img{
    height: 210px;
    width: 400px;
    display: block; /* remove extra space below image */
}
.model_image{
    height: auto;
    width: auto;
    display: block; /* remove extra space below image */
}
.modal-dialog{
    min-width: 800px !important;
    min-height: 800px  !important;
}

.videoframe{
  height: 450px !important;
}
