/* icon section */
.icons {

    #MiniLeftNav {
        position: fixed;
        top: 40%;
        right: 0;
        list-style: none;
        padding-left: 0;
        z-index: 10;
        margin: 0;
        -webkit-transition: right 0.25s ease-in-out;
        transition: right 0.25s ease-in-out;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    #MiniLeftNav li {
        list-style: outside none none;
        // margin: 10px 0;
        padding: 0;
        width: 40px;
    }

    #MiniLeftNav li a {
        border: solid 1px #fff;
        display: block;
        padding: 7px;
        position: relative;
        background: #3f4653;
    }

    #MiniLeftNav li a:hover {
        width: 40px;
        padding-left: 0;
        text-align: center;
        text-decoration: none;
        background: #E73853;
    }

    #MiniLeftNav span {
        font-family: 'Raleway', sans-serif;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
        bottom: 0;
        right: 47px;
        line-height: 38px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 0;

        /* CSS3 Transition: */
        -webkit-transition: 0.50s;
        /* Future proofing (these do not work yet): */
        -moz-transition: 0.50s;
        transition: 0.50s;
    }

    #MiniLeftNav a:hover span {
        width: auto;
        padding: 0 30px;
        overflow: visible;
        text-align: center;
        text-decoration: none;
        background: #E73853;
        color: #ffffff;
    }

    #MiniLeftNav a span {
        background-color: #fff;
        color: #3d4f0c;
    }

}

/* models section */
.models {
    margin-top:100px  !important;

    overflow-x: hidden;
    // padding-top: 20px;

    .xl\:h-screen {
        height: 680px !important;
        overflow-x: hidden !important;
        overflow-y: hidden !important;
    }

    iframe {
        overflow-x: hidden !important;
        overflow-y: hidden !important;
    }

    .modal-dialog {

        border-radius: 50px;

        h3 {
            text-align: center;
            text-transform: uppercase;
            font-size: 18px;
            margin-top: 30px;
            font-weight: 600;
            padding-left: 20%;
        }

        .container-fluid {
            .xl\:h-screen {
                height: 100% !important;
            }

            iframe {
                overflow-x: hidden !important;
                overflow-y: hidden !important;
            }
        }
    }

    h3 {
        text-align: center;
        margin: 30px auto;
        text-transform: uppercase;
    }

    i {
        border: 1px solid #dc2626;
        border-radius: 50%;
        font-size: 40px;
        color: #dc2626;
    }

    .spec {
        .table {
            border-top: 1.5px solid #b9b8b8;

            th {
                text-transform: uppercase;
                font-weight: 650;
                font-size: 15px;
            }

            td {
                text-transform: capitalize;
                // margin-left: 20%;
                // margin-right: 12%;
            }

            .h5 {
                text-transform: uppercase;
                font-weight: 650;
                font-size: 13px;
            }

        }
    }

    hr {
        border: 1px solid #b9b8b8;
        margin: 10px auto;
    }

    h6 {
        color: #141313;
        font-size: 17px;
        font-weight: 600;
        text-transform: capitalize;
        padding-top: 15px;
    }

    h4 {
        color: #0d0d0d;
        font-size: 22px;
        font-weight: 600;
        text-transform: capitalize;
    }

    p {
        font-weight: 14px;
        text-align: justify;
    }

    .seperate {
        background-color: #f3f3f2;
    }

    .top_features{
        margin: auto;
    }
    
}