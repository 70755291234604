.accessories {
  // padding-top: 20px;
  overflow-x: hidden;

  .sort {
    select {
      width: 30%;
      border: 2px solid #005597;
      text-transform: capitalize;
      // margin-left: 400px;
    }
  }

  h2 {
    text-transform: capitalize;
    text-align: center;
    margin: 20px auto;
  }

  .btn {
    width: 117px;
    height: 40px;
    text-transform: capitalize;
    border: 1px solid #dc2626;
    color: #dc2626;
    outline: none !important;
  }

  .btn:hover {
    background-color: #dc2626;
    color: #ffffff;
    outline: none !important;
  }

  .parts {
    padding-left: 30px;
    margin-top: 30px;

    .col-lg-3 {
      padding: 0 2% 2% 0;
    }

    .card {
      border-radius: 8px;

      img {
        width: 100%;
        height: 200px;
        border-radius: 8px 8px 0 0;
      }

      h6 {
        margin: 15px 5px 5px 10px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
      }

      .actions {
        margin: 0 0 10px 10px;
      }

      a {
        margin-top: 10px;
        justify-content: center;
        text-transform: capitalize;
        cursor: pointer;
        text-decoration: none;
        font-size: 14px;
        color: #212529;
      }

      a:hover {
        color: #dc2626;
        border-bottom: 1px solid #e5e7eb;
      }

      i {
        font-size: 10px;
      }

      span {
        position: relative;
        top: 7px;
        justify-content: center;
        text-transform: capitalize;
        cursor: pointer;
      }

      .inc {
        margin-left: 15px;
        cursor: pointer;
        color: #22c55e;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
      }

      .dec {
        margin-left: 15px;
        cursor: pointer;
        color: #dc2626;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
      }
    }

    .card:hover {
      transform: scale(1.05);
    }
  }
  .details img{
    width: 100% !important;
  }

  .accessory-model-open {
    .modal-header {
      background-color: #08192B;
      h3 {
        justify-content: center !important;
        color: #ffffff;
        text-transform: uppercase;
        font-size: 18px;
        margin-top: 10px;
        font-weight: 600;
      }

      .close {
        color: #ffffff;
      }
    }

    .accessory-model {
      img {
        width: 750px;
        height: auto;
      }

      a {
        justify-content: center;
        text-transform: capitalize;
        cursor: pointer;
        text-decoration: none;
        font-size: 14px;
        border: 1px solid #e5e7eb;
        border-radius: 5px;
        padding-left: 13px;
        width: 95px;
        height: auto;
      }

      span {
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 600;
      }

      .part {
        p {
          font-size: 20px;
          text-transform: capitalize;
        }

        .number {
          text-transform: uppercase;
        }
      }

      h6 {
        font-weight: 600;
        font-size: 16px;
      }
    }

    .table {
      th {
        text-transform: capitalize;
      }
      tr{
        border-bottom: 2px solid lightgray;
      }
      td {
        img {
          width: auto;
          height:40px;
        }

        text-transform: capitalize;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .modal-header .close{
      margin: 0 !important;
      padding:0 !important;
    }
    .form-control{
      border-radius:0;
    }

    form {
      button {
        background-color: #e40113;
        border: none;
        color: #ffffff;
        width: 150px;
        border-radius: 5px;
        text-transform: capitalize;
        margin: 0 35px 20px 0;
        height: 40px;
        font-weight: 700;
      }
      select,
      input {
        margin-top: 20px;
        text-transform: capitalize !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-bottom: 2px solid lightgray!important;
        outline: none !important;
        color: #35495e !important;
        font-size: 14px !important;
      }
      option,
      ::placeholder {
        text-transform: capitalize !important;
        border: none !important;
        outline: none !important;
        color: #35495e !important;
        font-size: 14px !important;
      }
    }

  

    thead{
      background-color: lightgray;
    }
    .close{
      float:none !important;
    }
    .mat-radio-button ~ .mat-radio-button {
      margin-left: 16px;
    }

    mat-form-field {
      width: 580px;
    }

    mat-label,
    mat-select,
    mat-radio-button {
      text-transform: capitalize;
    }

    ::ng-deep.mat-select-arrow {
      display: none;
    }
  }
}
