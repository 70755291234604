.not-found {
    text-align: center;
    padding: 150px;

  }
  
  .not-found h1 {
    font-size: 3rem;
    color: #ff0000;
  }
  
  .not-found p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .not-found a {
    font-size: 1.2rem;
    color: #007bff;
    text-decoration: none;
  }
  