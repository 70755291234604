.genuine-parts {
    overflow-x: hidden;

    h1 {
        color: #ffffff;
        position: absolute;
        left: 30px;
        top: 250px;
        z-index: 10;
        font-size: 60px;
        text-transform: uppercase;
        text-align: center;
        font-style: italic;
        font-family: Montserrat, sans-serif;
        font-weight: 900;

        span {
            opacity: 0.3;

        }

    }

    .quote {
        h4 {
            text-transform: uppercase;
            text-align: center;
            margin: 40px 15px;
            color: #017EC5;
            font-family: "Open Sans Condensed", sans-serif;
            font-size: 30px;
            font-weight: 700;
        }
    }

    form {
        button {
            background-color: #E40113;
            border: none;
            color: #ffffff;
            width: 150px;
            border-radius: 5px;
            text-transform: capitalize;
            margin: 0 35px 20px 0;
            height: 40px;
            font-weight: 700;
        }

         .email{
            margin-top: 40px;
        }

        input {
            margin: 20px 0px;
            width: 500px;
            height: 40px;
            outline: none !important;
            text-transform: capitalize !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-bottom: 2px solid lightgray !important;
            color: #35495E;
            font-size: 14px;
        }

        .form-control{
            border-radius:0;
          }

        select {
            margin: 20px 0;
            width: 500px;
            height: 40px;
            outline: none !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-bottom: 2px solid lightgray !important;
            text-transform: capitalize;
            color: #35495E;
            font-size: 14px;
        }

        option,
        ::placeholder {
            text-transform: capitalize;
            border: none;
            outline: none;
            color: #35495E;
            font-size: 14px;
        }

        #date {
            position: relative;
            bottom: 20px;
        }

        .mat-datepicker-toggle{
            position: relative;
            right: 35px;
            bottom: 20px;
        }
    }

}